<template>
    <div class="col s12 m5  l5 xl4 category">
        <div>
            <div class="page-subtitle">
                <h4>Создать категорию товара</h4>
            </div>

            <form class="category" @submit.prevent="submitHandler">

                <div class="input-field" >
                    <select ref="select" v-model="current">

                        <option v-for="r in routes" :key="r.path" :value="r.path"> {{r.name}}</option>

                    </select>

                    <label>Выберите доступный роут</label>

                </div>

                <div class="input-field">

                    <input
                            id="name"
                            type="text"
                            v-model="title"
                            :class="{invalid: $v.title.$dirty && !$v.title.required}"
                    >

                    <label for="name">Название категории </label>

                    <span  v-if="$v.title.$dirty && !$v.title.required"
                           class="helper-text invalid"

                    >
                        Введите название категории
                    </span>
                </div>

                <div class="input-field">

                    <input
                            id="path"
                            type="text"
                            v-model="path"
                            :class="{invalid: $v.path.$dirty && !$v.path.required}"
                    >

                    <label for="name">Путь категории по английски</label>

                    <span  v-if="$v.path.$dirty && !$v.path.required"
                           class="helper-text invalid"

                    >
                        Путь подкатегории по английски!
                    </span>
                </div>



                <button class="btn category-btn blue waves-effect waves-light" type="submit">
                    Создать
                    <i class="material-icons right">send</i>
                </button>
            </form>
        </div>
    </div>

</template>

<script>

    import { required } from 'vuelidate/lib/validators'
    export default {
        name: 'create-category',
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            routes: [],
            select: null,
            current: null,
            title: '',
            path: '',

        }),
        validations:{
            title: {required},
            path: {required},

        },

        watch:{
            current(router){
                const {path} = this.routes.find(r => r.path === router)

                this.path = path

            }
        },
        //устанавливаем первоначальные значения в селект
        created() {
            this.routes = this.$router.options.routes;

            this.current = this.routes[0].path;

            this.title = '';

            this.path = this.routes[0].path
        },

        mounted(){
            this.select = this.M.FormSelect.init(this.$refs.select);
            this.M.updateTextFields();
        },
        methods:{

            async submitHandler(){

                if (this.$v.$invalid){
                    this.$v.$touch();
                    return
                }

                try {
                    const category = await this.$store.dispatch('createCategory', {
                        title : this.title,
                        path :    this.path,
                    });
                    this.title = '';

                    this.$v.$reset();
                    this.$message('Категория успешно создана');
                    //родительский компонент прослушивает изменения дочернего
                    this.$emit('created', category)
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }
            }
        },


        destroyed() {
            if (this.select && this.select.destroy) {
                this.select.destroy
            }
        }
    }
</script>