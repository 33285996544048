<template>
    <div class="category-main">
        <div class="page-title">
            <h3>Категории товаров</h3>
        </div>
        <section >
            <Loader v-if="loading"/>
            <div class="row" v-else>
                <!--            родительский компонент прослушивает изменения дочернего created-->
                <CreateCategory :categories="categories"
                                @created="addNewCategory"/>

                <!--чтобы форсированно обновлять и перерисовывать элементы, можем использовать key
                тут установим мигалку которая при каждом добавлении  и суммировании будет обновлять компонент изза изменения
                значения кеу -->

                <EditCategory
                        v-if="categories.length"
                        :categories="categories"
                        :key="categories.length + updateCount"
                        @updatedCategory ="updatedCategory"/>

                <p v-else> Создайте новую категорию</p>

            </div>
        </section>
    </div>
</template>

<script>
    import CreateCategory from '@/components/admin/CreateCategory';
    import Loader from  '@/components/app/Loader';
    import EditCategory from  '@/components/admin/EditCategory';


    export default {
        name: 'categories',
        components:{
            CreateCategory, EditCategory, Loader
        },
        data: () => ({
            updateCount : 0,
            loading: true,
            categories: [],
        }),
        async mounted(){
            //загрузка категорий в элемент
            this.categories = await this.$store.dispatch('fetchCategories');
            console.log('КАТЕГОРИИ', this.categories)
            this.loading = false;


        },

        methods:{
            //слушаем изменения в дочернем элементе используя одноименные функции
            addNewCategory(category){
                this.categories.push(category);
            },

            //слушаем изменения в дочернем элементе используя одноименные функции
            updatedCategory(category){
                // ищем в массиве и обновляем данные в родительском компоненте (данные из селект)
                const catIndex = this.categories.findIndex(c => c.id === category.id)
                this.categories[catIndex].title = category.title
                this.categories[catIndex].limit = category.path
                this.updateCount++
            }
        }

    }
</script>

