<template>
    <div class="col s12 m6 l5 xl4 offset-m1 offset-l2 offset-xl2 edit-category">
        <div>
            <div class="page-subtitle">
                <h4>Редактировать категорию товара</h4>
            </div>

            <form >
                <div class="input-field" >
                    <select ref="select" v-model="current">

                        <option v-for="c of categories" :key="c.id" :value="c.id"> {{ c.title }}</option>

                    </select>

                    <label>Выберите категорию</label>

                </div>


                <div class="input-field">

                    <input
                            id="name"
                            type="text"
                            v-model="title"
                            :class="{invalid: $v.title.$dirty && !$v.title.required}"
                    >

                    <label for="name">Название</label>

                    <span  v-if="$v.title.$dirty && !$v.title.required"
                           class="helper-text invalid"

                    >
                        Введите название категории
                    </span>
                </div>

                <div class="input-field">

                    <input
                            id="path"
                            type="text"
                            v-model="path"
                            :class="{invalid: $v.path.$dirty && !$v.path.required}"
                    >

                    <label for="name">Название</label>

                    <span  v-if="$v.path.$dirty && !$v.path.required"
                           class="helper-text invalid"

                    >
                        Введите название категории
                    </span>
                </div>



                <button class="btn edit-category-btn blue waves-effect waves-light" @click.prevent="submitHandler">
                    Обновить
                    <i class="material-icons right">send</i>
                </button>

                <button class="btn btn-category-delete red waves-effect waves-light" @click.prevent="submitRemove">
                    <i class="material-icons right">delete</i>
                </button>
            </form>
        </div>
    </div>
</template>

<script>

    import { required} from 'vuelidate/lib/validators'
    export default {
        name: 'edit-category',
        props: {
            categories: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            select: null,
            title: '',
            path: '',
            current: null
        }),
        validations:{
            title: {required},
            path: {required},

        },
        watch:{
            current(categoryId){
                const {title, path} = this.categories.find(cat => cat.id === categoryId)
                this.title = title;
                this.path = path
            }
        },
        //устанавливаем первоначальные значения в селект
        created() {
            const {id, title, path } = this.categories[0];
            this.current = id;
            this.title = title;
            this.path = path;
        },
        mounted(){
            this.select = M.FormSelect.init(this.$refs.select)
            // M.updateTextFields()
        },
        methods:{
            async submitHandler(){
                if (this.$v.invalid){
                    this.$v.$touch()
                    return;
                }

                try {
                    const categoryData = {
                        id : this.current,
                        title: this.title,
                        path: this.path
                    };
                    //передаем объект категории для обновления на сервак
                    await this.$store.dispatch('updateCategory', categoryData);
                    this.$message('Категория обновлена');
                    // обновили данные на бэкэнде, надо передать в родителя, чтобы отрисовать опшнс в селекте
                    this.$emit('updatedCategory', categoryData)
                }   catch (e) {

                }
            },

            async submitRemove(){
                if (this.$v.invalid){
                    this.$v.$touch()
                    return;
                }

                try {
                    const categoryData = {
                        id : this.current,
                    };
                    //передаем объект категории для обновления на сервак
                    await this.$store.dispatch('deleteCategory', categoryData);
                    this.$message('Категория удалена');
                    // обновили данные на бэкэнде, надо передать в родителя, чтобы отрисовать опшнс в селекте
                    this.$emit('updatedCategory', categoryData)
                }   catch (e) {

                }
            }
        },
        destroyed() {
            if (this.select && this.select.destroy) {
                this.select.destroy
            }
        }
    }
</script>